// Libs
import React from "react";
import { css } from "emotion";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

// Components
import Button_DEPRECATED from "../../ui/Button_DEPRECATED";
import { resetBookings } from "../../../actions/bookingActions";

// Styles
import colors from "../../../styles/colors";
import SendIcon from "mdi-react/SendIcon";

// Utilities
import req from "../../../utilities/request-utility";

// Actions
import { addToast, hideModal } from "../../../actions/uiActions";

const ConfirmBookingModal = props => {
  const history = useHistory();

  const createBooking = () => {
    props.setLoading(true);
    props.hideModal();
    req()
      .post(
        `corporations/bookings?notSendEmail=${props.notSendEmail ? 1 : 0}&pastBooking=${props.pastBooking ? 1 : 0}`,
        props.booking
      )
      .then(() => {
        // Success
        props.addToast({
          message: "Nyt arrangement oprettet",
          type: "success",
          autoDismiss: true
        });
        props.resetBookings();
        history.push("/home");
      })
      .catch(err => {
        props.addToast({
          message:
            "Noget gik galt, da vi prøvede at oprette dit arrangement. Kontakt TjenerTeamet, hvis fejlen gentager sig",
          type: "error",
          autoDismiss: true
        });
      });
  };

  return (
    <div className={componentStyle(colors)}>
      <hr />

      <p className="title-header">Hvad sker der nu?</p>

      <ol>
        <li>
          1. Du modtager en bekræftelse på e-mail, når vi har godkendt din
          booking.
        </li>
        <li>
          2. Herefter vil vi finde vi de bedst kvalificeret kandidater til dig.
        </li>

        <li>
          3. Du vil modtage en e-mail, når vi har fundet det antal kandidater,
          som du har efterspurgt
        </li>
        <li>Har du nogen spørgsmål kan vi kontaktes på tlf. 3048 8484</li>
      </ol>

      <hr />
      <div className="btn-container">
        <Button_DEPRECATED
          className="cancel-btn"
          title={"Annullér"}
          onClick={() => props.hideModal()}
        />
        <Button_DEPRECATED
          className="submit-btn"
          onClick={() => createBooking()}
        >
          <SendIcon />
          {"Afsend booking"}
        </Button_DEPRECATED>
      </div>
    </div>
  );
};

const componentStyle = colors => css`
  padding: 0.5rem;

  .title {
    font-size: 1.25rem;
    font-weight: 500;
    color: ${colors.darkerGrey};
  }

  .title-header {
    font-size: 1.3rem;
    font-weight: 100;
    padding: 0.5rem 0rem;
    color: ${colors.darkerGrey};
  }

  ol {
    li {
      padding: 0.5rem 0rem;
      font-size: 0.85rem;
      color: ${colors.darkerGrey};
    }
  }

  hr {
    margin: 0.5rem;
  }

  .btn-container {
    display: flex;
    justify-content: flex-end;
  }

  .cancel-btn {
    width: auto;
    padding: 0.25rem 1rem;
    margin: 0 0.5rem;
    background-color: ${colors.white} !important;
    border: 1px solid ${colors.grey};
    color: ${colors.darkerGrey};
    font-size: 0.85rem;
  }
  .cancel-btn:hover {
    cursor: pointer;
    background-color: ${colors.lightGrey} !important;
  }

  .submit-btn {
    width: auto;
    padding: 0.25rem 1rem;
    margin: 0 0.5rem;
    font-size: 0.85rem;
    background-color: ${colors.mediumBlue};
  }

  .submit-btn:hover {
    cursor: pointer;
    background-color: ${colors.darkBlue};
  }

  svg {
    height: 1.25rem;
    margin-right: 0.5rem;
  }
`;

const mapDispatchToProps = dispatch => ({
  resetBookings: bindActionCreators(resetBookings, dispatch),
  hideModal: bindActionCreators(hideModal, dispatch),
  addToast: bindActionCreators(addToast, dispatch)
});

export default connect(null, mapDispatchToProps)(ConfirmBookingModal);
