// Libs
import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { css } from "emotion";

// Utilities
import req from "../../../utilities/request-utility";

// Components
import CheckOutEmployeeBox from "./CheckOutEmployeeBox";
import CheckOutAutoFillBox from "./CheckOutAutoFillBox";
import Spinner from "../../ui/Spinner";
import PageContainer from "../../ui/PageContainer";
import NavigateBackArrow from "../../ui/NavigateBackArrow";
import FinishedBooking from "../FinishedBooking";

// Actions / Redux
import { addToast } from "../../../actions/uiActions";
import ErrorBox from "../../ui/ErrorBox";

const CheckOutEmployees = props => {
  // Used to navigate from to pages
  const history = useHistory();

  // Booking dd from params (url)
  const { bookingId } = props.match.params;

  const [selectedShifts, setSelectedShifts] = useState(null);
  const [updatedShifts, setUpdatedShifts] = useState([]);
  const [booking, setBooking] = useState(null);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [error, setError] = useState(false);
  const [displayAutoFill, setDisplayAutoFill] = useState(false);
  const [autoFillData, setAutoFillData] = useState();

  // approve all shifts
  const approveAllShifts = async() => {
    // disable approve all button when any shift doesn't have checkin or checkout time.
    if(updatedShifts.length == 0){
      return;
    }
    // Start loading
    setLoading(true);
    // Update the employees shift
    await req().put(`shifts/corporation/approve-all-shifts`, {
      booking_id: booking.id,
      shifts: updatedShifts
    });
    // Success message
    props.addToast({
      message: `Tjek ud godkendt`,
      type: "success",
      autoDismiss: true
    });
    // Get checkout dates again
    loadData();
  }
 const setShiftsChanges = (checkout) => {
  let changedShift = updatedShifts;
  const changedShiftIndex = updatedShifts.findIndex(shift => shift.id == checkout.id)
  if(changedShiftIndex != -1){
    changedShift[changedShiftIndex] = checkout;
  }
  else{
    changedShift.push(checkout)
  }
  setUpdatedShifts(changedShift);
 }
  // checkout all employees
  const submitAutoFillCheckout = async(checkOut) => {
    setAutoFillData(checkOut);
  }
  // Checkout an employee
  const submitCheckout = async (employeeId, checkOut) => {
    // Start loading
    setBtnLoading(employeeId);
    // Update the employees shift
    await req().put(`shifts/corporation/checkout`, {
      employee_id: employeeId,
      booking_id: booking.id,
      checkOut
    });
    // Success message
    props.addToast({
      message: `Tjek ud godkendt`,
      type: "success",
      autoDismiss: true
    });
    setBtnLoading(false);

    // Get checkout dates again
    req()(`shifts/corporation/${bookingId}/checkout`).then(
      ({ data: selectedShifts }) => {
        setSelectedShifts(selectedShifts);
        setLoading(false);
      }
    );
  };
  // Save rating for an employee
  const submitRating = async (employeeId, rating) => {
    // Update the employees shift
    await req().put(`shifts/corporation/checkout/save-rating`, {
      employee_id: employeeId,
      booking_id: booking.id,
      rating
    });
    // Success message
    props.addToast({
      message: `Bedømmelse gemt`,
      type: "success",
      autoDismiss: true
    });
  };

  const loadData = async () => {
    try {
      const { data: booking } = await req()(
        `corporations/bookings/${bookingId}`
      );
      const { data: shifts } = await req()(
        `shifts/corporation/${bookingId}/checkout`
      );

      setBooking(booking);
      setSelectedShifts(shifts);
      setLoading(false);
    } catch (err) {
      props.addToast({
        message: "Kunne ikke hente oplysningerne..",
        type: "error"
      });
      setError(true);
      setLoading(false);
    }
  };

  // Get the employees shifts and the booking information
  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);
  return (
    <PageContainer showNavigation={true}>
      <div className={componentStyle()}>
        <NavigateBackArrow navigateURL={() => history.push({
          pathname: '/',
          state: { step: 1 }
        })} />
        {loading && !error && <Spinner title={"Henter vagter..."} />}
        {!loading && error && <ErrorBox />}
        {!loading && !error && (
          <Fragment>
            {/** Section 1 **/}
            <FinishedBooking className="finished-booking" booking={booking} selectedShifts={selectedShifts} readOnly={true} />
            <div className="auto-fill-container">
              <button className="auto-fill" onClick={() => setDisplayAutoFill(true)}>
                  AUTO UDFYLD
              </button>
              <button className="auto-fill approve-all" onClick={() => approveAllShifts()}>
                  GODKEND ALLE
              </button>
            </div>
            {/** Section 2 **/}
            <div className="section-two">
              {
                displayAutoFill && <div className="employee-container">
                <CheckOutAutoFillBox
                        submitCheckout={submitAutoFillCheckout}
                        booking={booking}
                        autoFillData={autoFillData}
                        />
              </div>
              }
              {selectedShifts ? selectedShifts?.map((shift, i) => (
                <div className="employee-container" key={i}>
                  <CheckOutEmployeeBox
                    autoFillData={autoFillData}
                    shift={shift}
                    submitCheckout={submitCheckout}
                    booking={booking}
                    key={i}
                    setShiftsChanges={setShiftsChanges}
                    submitRating={submitRating}
                    btnLoading={btnLoading}
                  />
                </div>
              )) : <></>}
            </div>
          </Fragment>
        )}
      </div>
    </PageContainer>
  );
};

const componentStyle = () => css`
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .section-two {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .employee-container {
    width: 50%;
    padding: 0.25rem;
  }
  .auto-fill-container {
    text-align: center;
    display: flex;
    justify-content: space-around;
    .auto-fill{
      background-color: #979797;
      color: #fff;
      font-size: 1rem;
      font-weight: 500;
      border-radius: 0.25rem;
      padding: 0.3rem 1rem;
      outline: none;
    }
  }
  @media screen and (max-width: 640px){
    .employee-container{
      width:70%;
    }
    .section-two{
      justify-content: center;
    }
  }
  @media screen and (max-width: 480px){
    .employee-container{
      width:100%;
    }
  }

`;

const mapDispatchToProps = dispatch => ({
  addToast: bindActionCreators(addToast, dispatch)
});

export default connect(null, mapDispatchToProps)(CheckOutEmployees);
